<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row v-show="isTableVisible">
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="memberships"
          item-key="Key"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :loading="loading"
          sort-by="Name"
          :sort-desc="true"
          show-expand
          :single-expand="false"
          :expanded.sync="expanded"
          dense
        >
          <template #[`item.UdfKey`]="{ value }">
            <v-btn
              color="success"
              @click="paymentsOpenMembershipCheckoutDetails(value.udfKey, value.name)"
            >
              {{
                value.status
                  ? $t("profile.membership.dataTable.action.additionalPayment")
                  : $t("profile.membership.dataTable.action.payOnline")
              }}
            </v-btn>
            <!-- <v-btn
              color="primary"
              outlined
              @click="fetchPaymentReceipt(value.udfKey)"
              v-if="value.status"
            >
              View Receipt
            </v-btn> -->
          </template>
          <template #[`item.Status`]="{ value }">
            <span :style="{ color: value ? 'green' : 'red' }">
              {{
                value
                  ? $t("profile.membership.dataTable.status.active")
                  : $t("profile.membership.dataTable.status.notPaid")
              }}
            </span>
          </template>
          <template #expanded-item="{ headers, item }">
            <td class="pa-0" :colspan="headers.length">
              <template v-if="item.Payments.length === 0">
                <v-list>
                  <v-list-item>
                    {{ $t("profile.membership.dataTable.item.noPaymentHistory") }}
                  </v-list-item>
                </v-list>
              </template>
              <template v-else>
                <v-list>
                  <v-list-item>
                    <v-row>
                      <v-col class="text-subtitle-2">
                        <span class="pl-4">{{
                          $t("profile.membership.dataTable.item.status")
                        }}</span>
                      </v-col>
                      <v-col class="text-subtitle-2 text-right">{{
                        $t("profile.membership.dataTable.item.amount")
                      }}</v-col>
                      <v-col class="text-subtitle-2 text-right">{{
                        $t("profile.membership.dataTable.item.dateCreated")
                      }}</v-col>
                      <v-col class="text-subtitle-2 text-right">
                        <span class="pr-4">{{
                          $t("profile.membership.dataTable.item.actions")
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-list-item v-for="payment in item.Payments" :key="payment.id">
                    <v-list-item-content>
                      <v-row>
                        <v-col class="pt-0 d-flex align-center">
                          <span
                            v-if="payment.payment_status_category"
                            class="pl-4"
                            :class="
                              payment.payment_status_category === 'SUCCESSFUL'
                                ? 'green--text'
                                : 'red--text'
                            "
                          >
                            <template v-if="payment.payment_status_category === 'SUCCESSFUL'">
                              {{ $t("profile.membership.dataTable.item.successful") }}
                            </template>
                            <template v-else>
                              {{ $t("profile.membership.dataTable.item.rejected") }}
                            </template>
                          </span>
                          <span class="pl-4 orange--text" v-else>{{
                            $t("profile.membership.dataTable.item.cancelled")
                          }}</span>
                        </v-col>
                        <v-col class="pt-0 d-flex align-center justify-end">
                          {{ payment.amount_of_money }} {{ payment.currency_code }}
                        </v-col>
                        <v-col class="pt-0 d-flex align-center justify-end text-body-1">
                          <template v-if="payment.payment_status_category">
                            {{ payment.transaction_date | formatDate }}
                          </template>
                          <template v-else>
                            {{ payment.created_at | formatDate }}
                          </template>
                        </v-col>
                        <v-col class="pt-0 text-right">
                          <v-btn
                            color="primary"
                            text
                            @click="fetchPaymentReceipt(payment.id)"
                            v-if="payment.payment_status_category === 'SUCCESSFUL'"
                          >
                            {{ $t("profile.membership.dataTable.item.viewReceipt") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="py-0">
                          <v-divider class="mx-4"></v-divider>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <payment-receipt
      :createdPaymentOutput="createdPaymentOutput"
      :type="'membership'"
      ref="paymentReceipt"
      @receipt-closed="isTableVisible = true"
    ></payment-receipt>
    <payments ref="payments" @payment-closed="paymentClosed" :type="'membership'"></payments>
    <v-progress-circular
      v-show="isReceiptLoading"
      class="loading-indicator"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script>
import Payments from "@/components/Payments/Payments";
import PaymentReceipt from "@/components/Payments/PaymentReceipt";
import MembershipApi from "@/api/MembershipApi";
import PaymentApi from "@/api/PaymentApi";
import Bus from "@/bus";
import { mapGetters } from "vuex";

export default {
  components: { Payments, PaymentReceipt },
  data: () => ({
    //before route enter return from Hosted Checkout in iFrame set main window to query string

    itemsTableFooterProps: {
      "items-per-page-options": [10, 20, 50, -1],
    },
    expanded: [],
    loading: false,
    isTableVisible: true,
    membershipApi: new MembershipApi(),
    paymentApi: new PaymentApi(),
    memberships: [],
    createdPaymentOutput: {},
    isReceiptLoading: false,
  }),
  computed: {
    ...mapGetters(["user"]),
    headers() {
      return [
        { text: this.$t("profile.membership.dataTable.header.membership"), value: "Name" },
        { text: this.$t("profile.membership.dataTable.header.status"), value: "Status" },
        { value: "UdfKey", align: "end", sortable: false },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  methods: {
    async initialize() {
      this.fetchCampaignsData();
    },
    async fetchCampaignsData() {
      this.loading = true;
      const result = await this.membershipApi.getList(this.user.id);
      this.loading = false;

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        return;
      }

      this.memberships = result;
    },
    async fetchPaymentReceipt(paymentId) {
      this.isTableVisible = false;
      this.isReceiptLoading = true;
      var receiptData = { userId: this.user.id, paymentId: paymentId };
      const result = await this.paymentApi.getReceiptById(receiptData);

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        this.isReceiptLoading = false;
        this.isTableVisible = true;
        return;
      }

      this.createdPaymentOutput = result.paymentReceipt;
      this.isReceiptLoading = false;

      this.$refs.paymentReceipt.open();
    },
    getCheckoutStatus(hostedCheckoutData) {
      this.$refs.payments.getCheckoutStatus(hostedCheckoutData);
      this.$refs.payments.isShowHostedCheckoutIFrame = false;
      this.deleteQueryString();
    },
    deleteQueryString() {
      let query = {};
      this.$router.replace({ query });
    },
    paymentsOpenMembershipCheckoutDetails(udfKey, campaignName) {
      this.isTableVisible = false;
      this.$refs.payments.openMembershipCheckoutDetails(udfKey, campaignName);
    },
    paymentClosed() {
      this.isTableVisible = true;
      this.fetchCampaignsData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  line-height: 4.5rem;
}
::v-deep .v-data-table-header th {
  font-size: 0.875rem !important;
  letter-spacing: 0.078rem;
  font-weight: 500;
}
::v-deep .v-data-table__wrapper tbody {
  td {
    font-size: 1rem !important;
  }
}
.loading-indicator {
  $size: 70px;
  position: absolute;
  top: calc(50% - #{$size} / 2);
  left: calc(50% - #{$size} / 2);
}
</style>
