<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row v-show="isTableVisible">
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="events"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :loading="loading"
          sort-by="Name"
          :sort-desc="true"
          dense
        >
          <template #[`item.Date`]="{ item }">
            {{ item.Date | formatEventDate }}
          </template>
          <template #[`item.UdfKey`]="{ item }">
            <v-btn
              color="success"
              @click="
                openPaymentsCheckout(item.UdfKey.udfKey, item.UdfKey.name, item.PriceNum, item)
              "
              v-if="!item.UdfKey.status"
            >
              {{ $t("profile.eventRegistration.dataTable.action.payOnline") }}
            </v-btn>
            <v-btn color="primary" text @click="fetchPaymentReceipt(item.UdfKey.udfKey)" v-else>
              {{ $t("profile.eventRegistration.dataTable.action.viewReceipt") }}
            </v-btn>
          </template>
          <template #[`item.Status`]="{ value }">
            <span :style="{ color: value ? 'green' : 'gray' }">
              {{
                value
                  ? $t("profile.eventRegistration.dataTable.status.registered")
                  : $t("profile.eventRegistration.dataTable.status.notRegistered")
              }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <PaymentReceipt
      :createdPaymentOutput="createdPaymentOutput"
      :type="'event'"
      ref="paymentReceipt"
      @receipt-closed="isTableVisible = true"
    ></PaymentReceipt>
    <Payments ref="payments" @payment-closed="paymentClosed" :type="'event'"></Payments>
    <v-progress-circular
      v-show="isReceiptLoading"
      class="loading-indicator"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script>
import Payments from "@/components/Payments/Payments";
import PaymentReceipt from "@/components/Payments/PaymentReceipt";
import EventApi from "@/api/EventApi";
import PaymentApi from "@/api/PaymentApi";
import Bus from "@/bus";
import { mapGetters } from "vuex";

export default {
  components: {
    Payments,
    PaymentReceipt,
  },
  data: () => ({
    itemsTableFooterProps: {
      "items-per-page-options": [10, 20, 50, -1],
    },
    loading: false,
    isTableVisible: true,
    eventApi: new EventApi(),
    paymentApi: new PaymentApi(),
    events: [],
    createdPaymentOutput: {},
    isReceiptLoading: false,
  }),
  computed: {
    ...mapGetters(["user"]),
    headers() {
      return [
        { text: this.$t("profile.eventRegistration.dataTable.header.name"), value: "Name" },
        { text: this.$t("profile.eventRegistration.dataTable.header.date"), value: "Date" },
        {
          text: this.$t("profile.eventRegistration.dataTable.header.price"),
          value: "Price",
          className: "text-right",
        },
        { text: this.$t("profile.eventRegistration.dataTable.header.status"), value: "Status" },
        { value: "UdfKey", align: "end", sortable: false },
      ];
    },
  },
  methods: {
    async initialize() {
      this.fetchEvents();
    },
    async fetchEvents() {
      this.loading = true;
      //   const result = await this.eventApi.getList(this.user.id);
      const result = await [];
      this.loading = false;

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        return;
      }

      this.events = result;
    },
    async fetchPaymentReceipt(udfKey) {
      this.isTableVisible = false;
      const receiptData = { userId: this.user.id, udfKey: udfKey };

      this.isReceiptLoading = true;
      const result = await this.paymentApi.getReceipt(receiptData);
      this.isReceiptLoading = false;

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        this.isTableVisible = true;
        return;
      }

      this.createdPaymentOutput = result.paymentReceipt;
      this.$refs.paymentReceipt.open();
    },
    getCheckoutStatus(hostedCheckoutData) {
      this.$refs.payments.getCheckoutStatus(hostedCheckoutData);
      this.$refs.payments.isShowHostedCheckoutIFrame = false;
      this.deleteQueryString();
    },
    deleteQueryString() {
      let query = {};
      this.$router.replace({ query });
    },
    openPaymentsCheckout(udfKey, udfName, price, item) {
      this.isTableVisible = false;
      this.$refs.payments.openEventCheckout(udfKey, udfName, price);
    },
    paymentClosed() {
      this.isTableVisible = true;
      this.fetchEvents();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  line-height: 4.5rem;
}
::v-deep .v-data-table-header th {
  font-size: 0.875rem !important;
  letter-spacing: 0.078rem;
  font-weight: 500;
}
::v-deep .v-data-table__wrapper tbody {
  td {
    font-size: 1rem !important;
  }
}
.loading-indicator {
  $size: 70px;
  position: absolute;
  top: calc(50% - #{$size} / 2);
  left: calc(50% - #{$size} / 2);
}
</style>
