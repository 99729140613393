import axios from "axios";
import Api from "./Api";
import { app } from "@/main.js";

class EventApi extends Api {
  constructor() {
    super("event", "event");
  }

  async getList(userId) {
    // const errorText = "Could not load list of events";
    const errorText = app.$i18n.t("errorMessage.eventApi.loadEvent");
    let result;

    try {
      let url = `/${this.endpoint}/list/${userId}`;
      result = await axios.get(url);
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }

    if (result.status === 200) return result.data;
    return { error: errorText };
  }

  // async register(hostedCheckoutData) {
  //   const errorText = "Payment failed";

  //   let result;
  //   try {
  //     let url = `/${this.endpoint}`;
  //     result = await axios.post(url, hostedCheckoutData);

  //     if (result.status === 200) {
  //       return result.data;
  //     }
  //     return { error: errorText };
  //   } catch (e) {
  //     console.log(e);
  //     if (e.response.status === 400 && e.response.data.error) {
  //       return e.response.data;
  //     }
  //     return { error: errorText };
  //   }
  // }
}

export default EventApi;
