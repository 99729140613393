<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-form v-model="valid" ref="profileForm">
      <v-row>
        <v-col>
          <v-text-field
            v-model="abEntry.FirstName"
            :label="$t('profile.profileForm.firstName')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="abEntry.LastName"
            :label="$t('profile.profileForm.lastName')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model.trim="abEntry.Email"
            ref="emailField"
            type="email"
            :rules="emailRules"
            required
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          >
            <template slot="label">
              {{ $t("profile.profileForm.email") }}<RedAsterisk></RedAsterisk>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="abEntry.Phone1"
            :label="$t('profile.profileForm.phone')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            v-model="abEntry['Udf/$TYPEID(19)']"
            :label="$t('profile.profileForm.languagesSpoken')"
            :items="languageSpokenDDL"
            item-text="Value"
            item-value="Key"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
            multiple
            chips
            deletable-chips
            small-chips
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            v-model="abEntry.Phone3"
            :label="$t('profile.profileForm.cell')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :value="
              abEntry.MembershipStatus
                ? $t('profile.profileForm.membershipStatusActive')
                : $t('profile.profileForm.membershipStatusNotPaid')
            "
            :label="$t('profile.profileForm.membershipStatus')"
            filled
            readonly
            hide-details
            :class="{
              'active-status-colour': abEntry.MembershipStatus,
              'expired-status-colour': !abEntry.MembershipStatus,
            }"
            ><template slot="append">
              <v-btn
                v-if="!abEntry.MembershipStatus"
                color="green"
                dark
                :disabled="false"
                style="margin-bottom: 6px"
                @click="openMembership"
              >
                {{ $t("profile.profileForm.button.payNow") }}
              </v-btn>
            </template></v-text-field
          >
        </v-col>
        <v-col>
          <v-text-field
            v-model="abEntry.Phone2"
            :label="$t('profile.profileForm.fax')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="abEntry.CompanyName"
            :label="$t('profile.profileForm.organization')"
            :filled="!isEditing || abEntry.Type != 'Individual'"
            :outlined="isEditing && abEntry.Type == 'Individual'"
            :disabled="!isEditing || abEntry.Type != 'Individual'"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="abEntry.Address.AddressLine1"
            :label="$t('profile.profileForm.address1')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="abEntry.Address.AddressLine2"
            :label="$t('profile.profileForm.address2')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="abEntry.Address.City"
            :label="$t('profile.profileForm.cityTown')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="abEntry.Address.StateProvince"
            :label="$t('profile.profileForm.stateProvince')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="abEntry.Address.ZipCode"
            :label="$t('profile.profileForm.zipPostal')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="abEntry.Address.Country"
            :label="$t('profile.profileForm.country')"
            :filled="!isEditing"
            :outlined="isEditing"
            :disabled="!isEditing"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <spinner ref="spinnerProfileForm"></spinner>
  </v-container>
</template>

<script>
import Bus from "@/bus";
import RedAsterisk from "@/components/Layout/RedAsterisk";
import { mapGetters } from "vuex";
import UserApi from "@/api/UserApi";
import MaximizerSchemaApi from "@/api/MaximizerSchemaApi";
import Spinner from "@/components//Layout/Spinner.vue";

export default {
  components: {
    RedAsterisk,
    Spinner,
  },
  data: () => ({
    valid: false,
    abEntry: { Address: {} },
    tempAbEntry: { Address: {} },
    isActive: false,
    isEditing: false,
    userApi: new UserApi(),
    maximizerSchemaApi: new MaximizerSchemaApi(),
    languageSpokenDDL: [],
  }),

  computed: {
    ...mapGetters(["user"]),
    emailRules() {
      return [
        (v) => {
          if (v !== "") {
            return /.+@.+\..+/.test(v) || this.$t("profile.profileForm.rule.email");
          }
          return false;
        },
      ];
    },
    isShowRenewMembership() {
      if (Object.keys(this.abEntry).length > 0) {
        if (this.abEntry.MembershipStatus) {
          switch (this.abEntry.MembershipStatus) {
            case "Expired":
              return true;
              break;
            case "Active":
              return false;
              break;

            default:
              return true;
              break;
          }
        }
      }
      return false;
    },
  },
  methods: {
    async initialize() {
      this.getSpokenLanguangeDDL();
      await this.getUserProfile();
    },
    async getSpokenLanguangeDDL() {
      const nameData = { name: "Language spoken" };
      const result = await this.maximizerSchemaApi.searchByName(nameData);
      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        return;
      }
      this.languageSpokenDDL = result[0].Items;
    },
    async getUserProfile() {
      const result = await this.userApi.getUserProfile(this.user.id);
      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        return;
      }
      this.abEntry = result;
      // Create copy of existing data
      this.tempAbEntry = JSON.parse(JSON.stringify(result));
    },
    enableEdit() {
      this.isEditing = true;
    },
    cancelEdit() {
      this.isEditing = false;
      this.abEntry = JSON.parse(JSON.stringify(this.tempAbEntry));
    },
    openMembership() {
      this.$emit("open-membership");
    },
    async saveEdit() {
      if (this.$refs.profileForm.validate()) {
        var userData = JSON.parse(JSON.stringify(this.abEntry));
        userData.Phones = [userData.Phone1, userData.Phone2, userData.Phone3, userData.Phone4];
        delete userData.MembershipStatus;

        this.$refs.spinnerProfileForm.on();
        const result = await this.userApi.editUserProfile(userData);
        if (result.error) {
          Bus.$emit("alert", "error", result.error);
          this.$refs.spinnerProfileForm.off();
          return;
        }

        this.$refs.spinnerProfileForm.off();
        Bus.$emit("alert", "success", this.$t("profile.profileForm.successMessage.profileUpdated"));
        this.getUserProfile();
        this.isEditing = false;
        this.$emit("is-editing", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$font-color: #444444;
$expired-font-color: red;
$active-font-color: green;
::v-deep .v-text-field--filled {
  label {
    color: $font-color;
  }
  input {
    color: $font-color;
  }
}
::v-deep .v-text-field--filled.active-status-colour {
  input {
    color: $active-font-color !important;
  }
}
::v-deep .v-text-field--filled.expired-status-colour {
  input {
    color: $expired-font-color !important;
  }
}
::v-deep .theme--light.v-chip:not(.v-chip--active) {
  background: #aaecb6;
}
::v-deep span.v-chip--select.v-chip {
  color: black;
}
::v-deep .v-chip--disabled {
  opacity: 0.8 !important;
}
</style>
