<template>
  <v-container class="content-container pa-0">
    <v-row>
      <v-col cols="12" md="8" class="pt-0">
        <v-tabs v-model="selectedTab">
          <v-tab>{{ $t("profile.tab.profile") }}</v-tab>
          <v-tab>{{ $t("profile.tab.membership") }}</v-tab>
          <v-tab>{{ $t("profile.tab.eventRegistration") }}</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="4" class="pt-0 d-flex justify-end align-center">
        <template v-if="selectedTab === 0">
          <template v-if="isEditing">
            <v-btn color="primary" large text @click="cancelEdit">{{
              $t("profile.button.cancel")
            }}</v-btn>
            <v-btn color="green" large dark @click="saveEdit">{{
              $t("profile.button.save")
            }}</v-btn>
          </template>
          <v-btn v-else color="primary" large outlined @click="enableEdit">{{
            $t("profile.button.edit")
          }}</v-btn>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <profile-form
          ref="profileForm"
          v-show="selectedTab === 0"
          @is-editing="setIsEditing"
          @open-membership="openMembership"
        ></profile-form>
        <membership ref="membership" v-show="selectedTab === 1"></membership>
        <event-registration ref="eventRegistration" v-show="selectedTab === 2"></event-registration>
      </v-col>
    </v-row>
    <spinner ref="spinner"></spinner>
  </v-container>
</template>

<script>
import Spinner from "../components/Layout/Spinner.vue";
import EventRegistration from "../components/Profile/EventRegistration.vue";
import Membership from "../components/Profile/Membership.vue";
import ProfileForm from "../components/Profile/ProfileForm.vue";

export default {
  components: {
    ProfileForm,
    Spinner,
    Membership,
    EventRegistration,
  },
  beforeRouteEnter(to, from, next) {
    if (
      getParameterByName("orderID") != null &&
      getParameterByName("SHASIGN") != null &&
      getParameterByName("amount") != null &&
      getParameterByName("STATUS") != null
    ) {
      let protocol = window.top.location.protocol;
      let host = window.top.location.host;
      let pathname = window.top.location.pathname

      window.top.location.href = `${protocol}//${host}/#/${pathname}/?orderID=${getParameterByName(
        "orderID"
      )}&currency=${getParameterByName("currency")}&amount=${getParameterByName(
        "amount"
      )}&PM=${getParameterByName("PM")}&ACCEPTANCE=${getParameterByName(
        "ACCEPTANCE"
      )}&STATUS=${getParameterByName("STATUS")}&CARDNO=${getParameterByName(
        "CARDNO"
      )}&ED=${getParameterByName("ED")}&CN=${getParameterByName("CN")}&TRXDATE=${getParameterByName(
        "TRXDATE"
      )}&PAYID=${getParameterByName("PAYID")}&NCERROR=${getParameterByName(
        "NCERROR"
      )}&BRAND=${getParameterByName("BRAND")}&IP=${getParameterByName(
        "IP"
      )}&SHASIGN=${getParameterByName("SHASIGN")}`;
    }

    function getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    next();
  },
  //before route update check query string to see if there is returnmac and hosted checkout id
  beforeRouteUpdate(to, from, next) {
    const orderId = to.query.orderID;
    const status = to.query.STATUS;
    const shaSign = to.query.SHASIGN;
    const amount = to.query.amount;

    if (
      from.path.includes("profile") &&
      orderId != null &&
      status != null &&
      shaSign != null &&
      amount != null
    ) {
      if (this.selectedTab === 1) {
        this.$refs.membership.getCheckoutStatus(to.query);
      } else if (this.selectedTab === 2) {
        this.$refs.eventRegistration.getCheckoutStatus(to.query);
      }
    }

    next();
  },
  data: () => ({
    selectedTab: 0,
    isEditing: false,
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$nextTick();
      this.$refs.membership.initialize();
      this.$refs.eventRegistration.initialize();

      this.$refs.spinner.on();
      await this.$refs.profileForm.initialize();
      this.$refs.spinner.off();
    },
    enableEdit() {
      this.isEditing = true;
      if (this.selectedTab === 0) {
        this.$refs.profileForm.enableEdit();
      }
    },
    cancelEdit() {
      this.isEditing = false;
      if (this.selectedTab === 0) {
        this.$refs.profileForm.cancelEdit();
      }
    },
    saveEdit() {
      if (this.selectedTab === 0) {
        this.$refs.profileForm.saveEdit();
      }
    },
    async setIsEditing(val) {
      if ((val != null) & (typeof val != "undefined")) {
        this.isEditing = val;
      }
    },
    openMembership() {
      this.selectedTab = 1;
    },
  },
};
</script>
